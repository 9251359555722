var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c(
        "div",
        {
          staticClass: "sidemenu-mask-dropdown",
          attrs: { id: "reports-" + _vm.year + _vm.item.id, type: _vm.item.id },
          on: {
            click: function (e) {
              _vm.isOpen = !_vm.isOpen
            },
            mouseover: function (e) {
              return _vm.ellipsis(e, true)
            },
            mouseleave: function (e) {
              return _vm.ellipsis(e, false)
            },
          },
        },
        [
          _c("div", { staticClass: "tooltip-hover", attrs: { open: false } }, [
            _c("i", {
              class: [
                "status-icon fa fa-chevron-right",
                _vm.isOpen ? "open" : "closed",
              ],
            }),
            !_vm.isOpen ? _c("i", { staticClass: "fa fa-folder" }) : _vm._e(),
            _vm.isOpen
              ? _c("i", { staticClass: "fa fa-folder-open" })
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.year) + " "),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "sidemenu-mask-dropdown-list",
          attrs: {
            "data-controlled-by": "reports-" + _vm.year + _vm.item.id,
            open: _vm.isOpen,
            level: _vm.getNextLevel() + 1,
          },
        },
        _vm._l(_vm.sortByNameDesc(_vm.reports), function (report) {
          return _c("div", { key: report.Id }, [
            _c(
              "div",
              {
                staticClass: "sidemenu-mask-item",
                style: _vm.padding,
                attrs: { report: report.id },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.getReport(report)
                  },
                  mouseover: function (e) {
                    return _vm.ellipsis(e, true)
                  },
                  mouseleave: function (e) {
                    return _vm.ellipsis(e, false)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: report.displayName,
                        },
                        expression:
                          "{\n                    content: report.displayName,\n                }",
                      },
                    ],
                    staticClass: "tooltip-hover",
                    attrs: { open: false },
                  },
                  [
                    _c("i", { staticClass: "fas fa-file" }),
                    _vm._v(" " + _vm._s(report.displayName) + " "),
                  ]
                ),
              ]
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }